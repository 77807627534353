.detail-description-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.custom-dropdown .ant-select-item {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px !important;
  // border: 1px solid black !important;
}

.custom-dropdown .ant-select-item-option-active {
  background-color: #f0f0f0;
}

.custom-dropdown .ant-select-item-option-selected {
  background-color: #f0f0f0;
  // border: 1px solid black !important;
}

.custom-dropdown {
  .ant-select-item {
    display: flex;
    justify-content: space-between;
  }
}

.font20Bold {
  font-size: 20px;
  font-weight: bold;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.discount-modal-button a {
  color: var(--text-color) !important;
}

body .detail-description-modal {
  .ant-modal-content {
  }
}

body .detail-description-title {
  text-align: center !important;
  font-size: 20px !important;
}

body .detail-modal-close svg {
  color: white !important;
}

body .discount-card-modal {
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0px !important;
    margin: 10px !important;
  }

  h2 {
    font-family: "ZemestroStd-Bold";
  }

  width: 100% !important;
  max-width: 800px;
  padding: 0 !important;

  .ant-modal-body {
    padding: 0;
  }

  .modal-image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .discount-modal-button {
    // position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    padding: 9px 20px 33px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-family: "ZemestroStd-Regular";
    width: 43%;
    margin: 10px auto;
  }
}

.discount-card-modal .ant-modal-close {
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  background-color: var(--background-color) !important;
}

.discount-card-modal .ant-modal-close:hover {
  background-color: var(--background-color) !important;
}

body .discount-card-modal .ant-modal-close svg {
  // color: #fff !important;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 6px;
}

body .islamic-modal {
  max-width: 100%;
}

body .islamic-modal .ant-modal-content {
  margin: 0 !important;
}

body .islamic-modal .discount-modal-button {
  position: absolute;
  width: max-content;
  margin: 0;
  background: unset;
  top: 0;
  right: -42px;
  left: unset;
  box-shadow: unset;
  background-color: unset !important;
  display: flex;
}

body .islamic-modal .discount-modal-button svg {
  color: #000;
  width: 20px;
  height: 20px;
}

body .discount-modal-button {
  display: none;
}
body .islamic-modal.discount-card-modal img {
  border-radius: unset;
}
@media (max-width: 767px) {
  .discount-card-modal {
    width: 90% !important;
  }

  body .discount-card-modal .discount-modal-button {
    padding: 3px 20px 30px 20px;
  }

  body .islamic-modal {
    height: 100vh;
  }

  body .islamic-modal .ant-modal-content {
    height: 100%;
  }

  body .islamic-modal .ant-modal-body {
    height: 100%;
  }
}
