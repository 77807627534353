@font-face {
  font-family: "Inter-Black";
  src:
    local("Inter"),
    url(../Fonts/Inter/static/Inter-Black.ttf) format("opentype");
}
@font-face {
  font-family: "Inter-Extralight";
  src:
    local("Inter"),
    url(../Fonts/Inter/static/Inter-ExtraLight.ttf) format("opentype");
}
@font-face {
  font-family: "Inter-Light";
  src:
    local("Inter"),
    url(../Fonts/Inter/static/Inter-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Inter-Regular";
  src:
    local("Lato"),
    url(../Fonts/Inter/static/Inter-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Lato"), url(../Fonts/Inter/static/Inter-Medium.ttf) format("opentype");
}
// @font-face {
//   font-family: "Lato-Light";
//   src: local("Lato"), url(../Fonts/Lato/Lato-Light.ttf) format("opentype");
// }
// @font-face {
//   font-family: "Lato-LightItalic";
//   src: local("Lato"), url(../Fonts/Lato/Lato-LightItalic.ttf) format("opentype");
// }
// @font-face {
//   font-family: "Lato-Regular";
//   src: local("Lato"), url(../Fonts/Lato/Lato-Regular.ttf) format("opentype");
// }
// @font-face {
//   font-family: "Lato-Thin";
//   src: local("Lato"), url(../Fonts/Lato/Lato-Thin.ttf) format("opentype");
// }
// @font-face {
//   font-family: "Lato-ThinItalic";
//   src: local("Lato"), url(../Fonts/Lato/Lato-ThinItalic.ttf) format("opentype");
// }
