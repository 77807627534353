@font-face {
  font-family: "Lato-Black";
  src: local("Lato"), url(../Fonts/Lato/Lato-Black.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-BlackItalic";
  src: local("Lato"), url(../Fonts/Lato/Lato-BlackItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato"), url(../Fonts/Lato/Lato-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-BoldItalic";
  src: local("Lato"), url(../Fonts/Lato/Lato-BoldItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-Italic";
  src: local("Lato"), url(../Fonts/Lato/Lato-Italic.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-Light";
  src: local("Lato"), url(../Fonts/Lato/Lato-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-LightItalic";
  src: local("Lato"), url(../Fonts/Lato/Lato-LightItalic.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-Regular";
  src: local("Lato"), url(../Fonts/Lato/Lato-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-Thin";
  src: local("Lato"), url(../Fonts/Lato/Lato-Thin.ttf) format("opentype");
}
@font-face {
  font-family: "Lato-ThinItalic";
  src: local("Lato"), url(../Fonts/Lato/Lato-ThinItalic.ttf) format("opentype");
}
