.brandSlider {
  width: 100%;
  position: relative;

  &__title {
    text-align: center;
    font-size: 50px;
    margin-bottom: 16px;
    color: var(--background-color);
    background-color: var(--background-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  &__wrapper {
    display: flex;
    gap: 60px;
    min-width: max-content;
    animation: scrollBrands 20s linear infinite;
  }

  @keyframes scrollBrands {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  &__item {
    min-width: 120px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    max-width: 100%;
    height: 100px;
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .brandSlider {
    &__title {
      font-size: 20px;
    }

    &__wrapper {
      gap: 32px;
      padding: 16px 24px;
    }

    &__item {
      min-width: 100px;
      height: 80px;
    }
  }
}
