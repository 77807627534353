.BannerSwiper {
  margin: auto !important;
  max-width: 100% !important;
  position: relative !important;

  .carousel_container {
    .ant-carousel {
      max-width: 100% !important;

      .slick-arrow {
        background-color: #fff;
        width: 30px;
        height: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        svg {
          width: 20px;
          height: 20px;
          color: var(--background-color);
        }
      }

      .slick-slider {
        max-width: 100% !important;
        position: relative !important;

        .slick-prev {
          left: 0px !important;
        }

        .slick-prev:before,
        .slick-next:before {
          display: none !important;
        }

        .slick-next {
          right: 0px !important;
        }

        .slick-list {
          .slick-track {
            display: flex !important;
          }
        }

        .slick-dots {
          z-index: 1 !important;
          bottom: -15px !important;

          li {
            width: 12px;
            height: 12px;
          }

          button {
            background: #cecece !important;
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          button::before {
            display: none !important;
          }

          .slick-active button {
            background: var(--background-color) !important;
          }
        }
      }
    }
  }
}
