.tabs_section {
  width: 100%;
  margin: 15px auto;

  .tabs_container {
    // background: #444444;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ffffff !important;
    cursor: pointer;
    .ant-typography {
      color: #ffffff;
      font-size: 14px;
      &:hover {
        font-weight: 500;
      }
    }
  }
}

.mobile_tabs_section {
  margin: 10px 0px;
  margin-top: 0;

  .tabs_container {
    //    background: #444444;
    height: 32px;

    .ant-typography {
      font-size: 13px;
      font-family: "ZemestroStd-Medium" !important;
    }
  }
}
