@import "../../../assets/Fonts/Fonts.scss";
@import "../../../styles/Colors.scss";
// .BannerCarousel {
//   .carousel_container {
//     .slick-slide {
//       min-width: max-content !important;
//       width: max-content !important;
//       height: auto !important;
//     }
//     .slick-slide > div {
//       // min-width: 100% !important;
//       width: max-content !important;
//       height: auto !important;
//     }
//   }
// }
.bannerCarouselHomepage {
  .sectionHeadingContainer {
    .sectionHeadingBold {
      text-align: left;
      // margin: 10px !important;
      font-size: 26px !important;
      font-family: "ZemestroStd-Medium" !important;
      color: $secondaryTwo !important;
      font-weight: 100;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        bottom: 6px;
        width: 13px;
        background-color: var(--background-color);
        border-radius: 3px;
        display: inline-block;
      }
    }

    .sectionHeadingMobileBold {
      text-align: left;
      margin: 10px !important;
      font-size: 25px !important;
      color: $secondaryTwo !important;
      font-family: "ZemestroStd-Medium" !important;
      font-weight: 100 !important;
      padding-left: 10px !important;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        bottom: 6px;
        width: 10px;
        background-color: var(--background-color);
        border-radius: 3px;
        display: inline-block;
      }
    }

    .sectionHeadingSemiBold {
      text-align: left;
      margin: 10px !important;
      font-size: 24px !important;
      color: $secondaryTwo !important;
      font-family: "ZemestroStd-Medium" !important;
      font-weight: 100 !important;
    }
  }
}
.slider-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0;
  &::-webkit-scrollbar {
    display: none;
  }

  .slide {
    flex: 0 0 90%;
    width: 100%;
    margin-right: 10px;
    scroll-snap-align: start;

    a {
      display: block;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}
.BannerCarousel {
  margin-bottom: 5px;
  margin-top: 10px;
}
.banner_carousel {
  // img {
  //   //  width: 99%;
  //   min-height: 400px;
  // }
  .slick-arrow {
    background-color: #fff !important;
    width: 25px;
    height: 25px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    svg {
      width: 18px;
      height: 18px;
      color: var(--background-color);
      display: flex;
      left: 2px;
      position: relative;
    }
  }
  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .ant-image {
    width: 100%;

    .ant-image-img {
      height: 420px;
    }
  }

  .slick-next {
    right: 0 !important;
    .next-btn-container {
      position: absolute;
      left: 0px;
    }
  }
  .slick-prev {
    left: 0 !important;
    .prev-btn-container {
      position: absolute;
      right: 0px;
    }
  }

  // .slick-dots li button:before {
  //   color: orange;
  // }

  .ant-carousel {
    ul.slick-dots {
      bottom: -14px !important;
      z-index: 1 !important;
      li {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      button {
        background: #cecece !important;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      button::before {
        display: none !important;
      }
      li.slick-active {
        button {
          background: var(--background-color) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .BannerCarousel {
    margin-bottom: 5px;
  }
}
