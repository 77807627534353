@font-face {
  font-family: "ZemestroStd-Light";
  src: local("ZemestroStd"),
    url(https://alfamall-bucket.s3.me-central-1.amazonaws.com/alfa-uat/fonts/ZemestroStd-Light.ttf)
      format("opentype");
}

@font-face {
  font-family: "ZemestroStd-LightItalic";
  src: local("ZemestroStd"),
    url(https://alfamall-bucket.s3.me-central-1.amazonaws.com/alfa-uat/fonts/ZemestroStd-LightItalic.ttf)
      format("opentype");
}

@font-face {
  font-family: "ZemestroStd-Regular";
  src: local("ZemestroStd"),
    url(https://alfamall-bucket.s3.me-central-1.amazonaws.com/alfa-uat/fonts/ZemestroStd-Regular.ttf)
      format("opentype");
}

@font-face {
  font-family: "ZemestroStd-RegularItalic";
  src: local("ZemestroStd"),
    url(https://alfamall-bucket.s3.me-central-1.amazonaws.com/alfa-uat/fonts/ZemestroStd-RegularItalic.ttf)
      format("opentype");
}

@font-face {
  font-family: "ZemestroStd-Medium";
  src: local("ZemestroStd"),
    url(https://alfamall-bucket.s3.me-central-1.amazonaws.com/alfa-uat/fonts/ZemestroStd-Medium.ttf)
      format("opentype");
}

@font-face {
  font-family: "ZemestroStd-Bold";
  src: local("ZemestroStd"),
    url(https://alfamall-bucket.s3.me-central-1.amazonaws.com/alfa-uat/fonts/ZemestroStd-Bold.ttf)
      format("opentype");
}
