.image-carousel-title {
  text-align: center;
}

.carousel-container {
  max-width: 100%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 0 8px;

  .image-carousel-images {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    vertical-align: middle;
  }

  .ant-carousel .slick-dots {
    bottom: -25px;
    z-index: 1;

    li {
      width: 12px;
      height: 12px;

      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: relative;
        transition: background-color 0.3s ease;
        background-color: gray;
        border: none;
      }

      button:before {
        display: none;
        font-size: 0;
      }

      button:after {
        display: none;
      }

      &.slick-active {
        width: 12px;
        height: 12px;

        button {
          background-color: var(--background-color);
        }
      }
    }
  }
}
