@import "../../../styles/Colors.scss";

.multi-usable-layout {
  padding: 20px 0px;
  background-color: #fff;

  .header-mus {
    text-align: left;
    margin-bottom: 20px;
    position: relative;

    .main-title {
      color: #333;
      margin: 0;
      display: block;
      padding-left: 30px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        bottom: 6px;
        width: 13px;
        background-color: var(--background-color);
        border-radius: 3px;
        display: inline-block;
      }
    }

    .description {
      color: #666;
      display: block;
      margin-left: 30px;
    }
  }

  .features {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      /* Hide scrollbar for Chrome, Safari, and Opera */
    }

    &.mobile {
      flex-wrap: nowrap;
      gap: 16px;
    }

    &.desktop {
      flex-wrap: nowrap;
      gap: 16px;
    }

    .feature-item {
      background-color: #fbfbfb;
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      transition: transform 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 300px;
      /* Fixed height for all cards */
      min-width: calc(25% - 16px);
      /* Adjust width for 4 items on mobile */
      flex: 0 0 calc(25% - 16px);
      /* Adjust width for 4 items on mobile */

      @media (min-width: 768px) {
        min-width: calc(20% - 16px);
        /* Adjust width for 5 items on desktop */
        flex: 0 0 calc(20% - 16px);
        /* Adjust width for 5 items on desktop */
      }

      &:hover {
        transform: translateY(-5px);
      }

      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }

      .card-title {
        font-size: 16px;
        color: #333;
        margin: 10px 0;
      }

      .discount {
        background-color: var(--background-color) !important;
        color: white;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 29px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
