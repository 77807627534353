.CategoriesHeader {
  max-width: 100%;
  margin: auto;

  .category-header-inner {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 10px 0px;
    margin: 5px 0px;
    gap: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .category-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 10px;
    border-radius: 50px;
    text-decoration: none;
    background-color: var(--background-color);
    white-space: nowrap;

    .category-icon {
      filter: brightness(0) invert(1);
    }
  }

  .category-content {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  .category-icon {
    width: auto;
    height: 15;
  }

  .font12Light {
    font-size: 12px;
    color: #ffffff;
  }
}

// Desktop styles

.categoriesheader-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  .category-item {
    width: 23%;
    text-align: center;
    padding: 10px;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    .category-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .category-name {
        font-size: 20px;
        font-weight: bold;
      }

      .category-icon {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1024px) {
  .categoriesheader-categories {
    .category-item {
      width: 30%;
    }
  }
}
